<template>
  <div class="loginContent">
    <header><img src="../../assets/login/logo.png"></header>
    <div class="home">
      <div class="bg">
        <div class="login">
          <div class="title"> <span>找回密码</span> </div>
          <el-form ref="ruleForm" :model="ruleForm" class="login_form" :rules="rules">
            <el-form-item prop="phone">
              <div class="label">
                手机号
              </div>
              <el-input v-model="ruleForm.phone" autocomplete="off" maxlength="11" placeholder="请输入您的手机号" class="el-input__block"></el-input>
            </el-form-item>
            <el-form-item prop="VCode" class="captcha">
              <div class="label">
                验证码
              </div>
              <div class="el-input__block">
                <el-input v-model="ruleForm.VCode" autocomplete="off" placeholder="请输入短信验证码" v-if="countdown" class="get_input">
                  <el-button slot="append" type="primary" class="getCaptcha" style="width: 177px" @click="getCaptchas" :disabled="canGet" :loading="codeLoading">获取短信验证码</el-button>
                </el-input>
                <el-input v-model="ruleForm.VCode" autocomplete="off" placeholder="请输入短信验证码" v-else class="countdown">
                  <el-button slot="append" class="deactivation" style="background-color:#e6edf5;width: 177px">{{second}}秒后重新获取</el-button>
                </el-input>
              </div>
            </el-form-item>
            <el-form-item prop="password" class="pwd" style="margin-bottom: 0;">
              <div class="label">
                密码
              </div>
              <el-input :type=changeType(this.ruleForm.password,this.show) v-model="ruleForm.password" autocomplete="new-password" placeholder="请输入密码" class="el-input__block" :class="{error_input:isError}" maxlength="20"></el-input>
              <i :class="[show?'iconfont iconicon-28':'iconfont iconicon-27']" style="color:#409EFF;" @click="show=!show" v-if="ruleForm.password&&ruleForm.password.length>0"></i>
              <!--<span class="tip">8-16位,同时包含数字和字母</span>-->
            </el-form-item>
            <div :class="{tip:true,error:isError}" style="margin-top:0;height: 1.55rem;">
              {{errorMsg}}
            </div>
            <el-form-item prop="confirmPassword" class="pwd">
              <div class="label">
                确认密码
              </div>
              <el-input :type=changeType(this.ruleForm.confirmPassword,this.cshow) v-model="ruleForm.confirmPassword" autocomplete="new-password" placeholder="请输入确认密码" class="el-input__block" maxlength="20"></el-input>
              <i :class="[cshow?'iconfont iconicon-28':'iconfont iconicon-27']" style="color:#409EFF;" @click="cshow=!cshow" v-if="ruleForm.confirmPassword&&ruleForm.confirmPassword.length>0"></i>
            </el-form-item>
            <el-form-item>
              <div class="label">
              </div>
              <el-button type="primary" class="btn" @click="submitForm('ruleForm')" :disabled="canLogin" :loading='isLoading'>确认</el-button>
              <el-button type="primary" class="btn" @click=back()>取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!--<el-link href="/" class="logo" :underline="false"></el-link>-->
      <msg :icon="icon" :message="message" :color='color' :promptVisible="promptVisible" :title="title" :column="true" :row="false" top="22vh" class="employ_msg" :showClose="true" />
    </div>
    <!-- <div class="app-footer">
      <p class="mt60">地址：武汉市东湖开发区华中科技大学科技园天喻楼 | 邮政编码：430223 | 电话：027-87920300</p>
      <p class="mb60">传真：027-87920306 | E-Mail：webmaster@whty.com.cn | 鄂ICP备05002327 | 鄂公安网安备42018502000263号</p>
    </div> -->
  </div>
</template>

<script>
  import encrypt from '@/common/jsencrypt'
  import { mapGetters, } from 'vuex'
  import { getCaptcha ,getReset, isExist } from '@/api/user'
  export default {
    data() {
      var telValidate = (rule, value, callback) => {
        const reg = /^1[3|4|5|7|8|9][0-9]{9}$/
        this.canGet = true
        if (!value || value.length > 11 || !reg.test(value)) {
          callback(new Error('请输入有效的手机号'))
        } else {
          isExist({"phone":this.ruleForm.phone}).then(res =>{
            if(res.success){
              this.canGet = false
              callback()
            }else{
              callback(new Error('该手机号未注册'))
            }
          }).catch(err => {
            //einvAlert.error("提示",err)
          })
        }
      };
      var validatePass = (rule, value, callback) => {
        var reg = /^(?![^A-z]+$)(?!\\D+$)[A-z\\d]{8,16}$/
        if (value === '') {
          this.errorMsg = '请输入密码';
          this.isError = true;
        }else if(!reg.test(value)){
          this.errorMsg = '必须包含数字、字母，长度至少为8位且不超过16位';
          this.isError = true;
        }else if(this.ruleForm.confirmPassword !== ''){
          this.$refs.ruleForm.validateField('confirmPassword')
          this.isError = false;
          callback();
        } else {
          this.isError = false;
          this.errorMsg ='8-16位,同时包含数字、字母'
          callback();
        }
      };
      var validateConfirmPass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.password) {
          callback(new Error('两次输入密码不一致'));
        } else {
          callback();
        }
      };
      return {
        second: 60,
        countdown: true,
        promptVisible : false,
        icon : 'iconfont iconicon1-17',
        color: '#35C4B1',
        message : '修改提示',
        title: '找回密码',
        canLogin: false,
        // 是否能获取验证码
        canGet: false,
        isError: false,
        errorMsg: '8-16位,同时包含数字和字母',
        ruleForm: {
          password: '',
          confirmPassword: '',
          phone: '',
          VCode: '',
        },
        codeSrc: '',
        rules: {
          phone: [
            { required: true, validator: telValidate, trigger: 'blur' }
          ],
          password: [
            // { required: true, message: '请输入密码', trigger: 'blur' },
            // { min: 8, max: 20, message: '密码长度至少为8位且不超过20位' },
            // { pattern: '(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}', message: '密码必须包含数字、字母和符号', trigger: 'blur' }
            { required: true, validator: validatePass, trigger: 'blur' }
          ],
          confirmPassword: [
            { required: true, validator: validateConfirmPass, trigger: 'blur' }
          ],
          VCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
        checked: false,
        show: true,
        cshow: true,
        type: 'password',
        num: 5,
        isLoading: false,
        codeLoading : false,
      }
    },
    computed: {
      ...mapGetters([
        'reset'
      ])
    },
    mounted() {
      if (localStorage.getItem('oldTime')) {
        this.oldTime = localStorage.getItem('oldTime');
        var nowTime = new Date().getTime() - this.oldTime;
        /*if (nowTime < 60000) {
          this.second = 60 - parseInt(nowTime / 1000);
          this.countdown = false;
          this.lastSecond();
        }*/
      }
    },
    methods: {
      back() {
        if (this.$route.query.noGoBack) {
          this.$router.push({ path: '/' })
        } else {
          this.$router.go(-1)
        }
      },
      // 提交表单
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.isLoading = true
            getReset({
              ...this.ruleForm,
              password:encrypt.encrypt(this.ruleForm.password),
              confirmPassword:encrypt.encrypt(this.ruleForm.confirmPassword),
              }).then(res => {
              if (res.success) {
/*                this.message = '恭喜您找回密码成功！'
                this.promptVisible = true
                setTimeout(() => {
                  this.promptVisible = false
                }, 1000)*/

                einvAlert.success('找回成功','恭喜您找回密码成功！')
                setTimeout(() => {
                  sessionStorage.clear();
                  localStorage.clear();
                  this.$router.replace('/login')
                }, 2000)
                // einvAlert.success("提示",'恭喜您找回密码成功！');
                // this.$router.replace('/login');
                this.ruleForm = {
                  password: '',
                  confirmPassword: '',
                  phone: '',
                  VCode: ''
                }
              } else {
                this.isLoading = false;
                // einvAlert.error("提示",res.msg);
                einvAlert.error('找回失败',res.msg)
/*                this.message = res.msg
                this.promptVisible = true
                setTimeout(() => {
                  this.promptVisible = false
                }, 1000)*/
              }
            }).catch(err => {
              // einvAlert.error("提示",err)
              einvAlert.error('找回失败',err)
/*              this.message = err
              this.promptVisible = true
              setTimeout(() => {
                this.promptVisible = false
              }, 1000)*/
            })
            this.isLoading = false
          } else {
            this.isLoading = false
            console.log('error submit!!')
            return false
          }
        })
      },
      // 获取短信验证码
      getCaptchas() {
        this.codeLoading = true
        if (this.ruleForm.phone) {
            var date = new Date();
            var oldTime = date.getTime();
            localStorage.setItem('oldTime', oldTime);
            this.countdown = false;
            this.second = 60;
            this.lastSecond();
            getCaptcha({
              phone: this.ruleForm.phone,
              type : 2,
            }).then(res => {
              if (res.success) {
/*                this.message = '验证码发送成功'
                this.promptVisible = true
                setTimeout(() => {
                  this.promptVisible = false
                }, 1000)*/
                // einvAlert.success("提示",'验证码发送成功')
                einvAlert.success('验证码','验证码发送成功')
                this.codeLoading = false
              } else {
               /* this.message = res.msg
                this.promptVisible = true
                setTimeout(() => {
                  this.promptVisible = false
                }, 1000)*/
                // einvAlert.error("提示",res.msg)
                einvAlert.error('验证码',res.msg)
                this.codeLoading = false
              }
            }).catch(err => {
/*              this.message = err
              this.promptVisible = true
              setTimeout(() => {
                this.promptVisible = false
              }, 1000)*/
              // einvAlert.error(err)
              einvAlert.error('验证码',res.msg)
              this.codeLoading = false
            })
          }else{
/*            this.message = '请输入手机号'
            this.promptVisible = true
            setTimeout(() => {
              this.promptVisible = false
            }, 1000)*/
            einvAlert.error('验证码','请输入手机号')
            this.codeLoading = false
            // setTimeout(()=>{
            //   einvAlert.error('请输入手机号')
            //   this.codeLoading = false;
            // },500)
          }
      },
      // 倒计时
      lastSecond() {
        var clearSecond = setInterval(() => {
          this.second--
          if (this.second <= 0) {
            this.countdown = true
            clearInterval(clearSecond)
          }
        }, 1000);
      },
      // 是否显示密码
      changeType(password,show) {
        if (password.length == 0) {
          show = true
        }
        if (show == true) {
          return 'password'
        } else {
          return 'text'
        }
      },
    }
  }
</script>

<style lang="less" scoped>
  .loginContent {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  header{
    opacity: 1;
    width: 100%;
    height: 48px;
    line-height: 42px;
    background: #ffffff;
    border-bottom: 0.15rem solid #F1F3FF;
    box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.20);
    font-size: 20px;
    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
    font-weight: 700;
    text-align: left;
    color: #333333;
    text-indent: 30px;
    padding-top: 8px;
    img {
      height: 24px;
    }
  }
  .home {
    height: 85%;
    width: 100%;
    background-size: 100% 100%;
    position: relative;
    opacity: 1;
    background: #eff3f9;
    .logo {
      position: absolute;
      top: 24px;
      left: 42px;
      background: url("~@/assets/logo.png") no-repeat;
      background-size: 100% 100%;
      width: 170px;
      height: 40px;
    }
    .bg {
      position: absolute;
      top: 51%;
      left: 50%;
      width: 960px;
      height: 800px;
      transform: translate(-50%, -50%);
      opacity: 1;
      background: #ffffff;
      .left {
        position: absolute;
        width: 50%;
        height: 100%;
        background-position: center;
        left: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .login {
        position: absolute;
        border-radius: 20px;
        background-color: #fff;
        left: 9.2rem;
        top: 0;
        bottom: 0;
        height: 30.5rem;
        width: 30.5rem;
        padding: 42px 50px 32px;
        .title {
          text-align: center;
          margin-bottom: 50px;
          font-size: 28px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          span{
            border-bottom: 0.18rem solid #4f71ff;
            padding-bottom: 7px;
          }
        }
        .login_form {
          .captcha .countdown /deep/.el-input-group__append {
            padding: 0;
            background-color: #aaaaaa;
            width: 80px;
            .deactivation {
              margin: 0;
              width: 100%;
              background-color: #aaaaaa !important;
              border: 1px solid #aaaaaa;
            }
          }
          /deep/.el-input-group__append {
            border: 1px solid #dcdfe6;
            padding: 0;
            width: 80px;
            color: #fff;
            vertical-align: unset;
            .getCaptcha {
              margin: 0;
              border-radius: 0;
              width: 100%;
            }
            .el-button--medium {
              // padding: 0;
              background-color: #66b1ff;
              border: 1px solid #66b1ff;
              height: 48px;
            }
          }
          .pwd i {
            position: absolute;
            right: 14px;
            top: 56%;
            transform: translateY(-50%);
          }
          .el-form-item {
            margin-bottom: 20px;
            position: relative;
            .error {
              position: absolute;
              bottom: -40px;
              color: #e80000;
            }
            .el-button {
              width: 100%;
            }
            .button{
              margin-top: 8px;
              height: 46px;
              font-size: 16px;
            }
            .iconxianshi {
              color: #4f71ff !important;
            }
            /deep/ .el-input__inner {
              color: #222;
              height: 48px;
            }
            .label {
              display: inline-block;
              text-align: right;
              margin-right: 10px;
              width: 15%;
              justify-content: space-between;
              font-size: 18px;
              .el-link {
                font-size: 18px;
                display: inline-block;
              }
            }
            .el-input__block {
              display: inline-block;
              width: 82%;
            }
            /deep/ .el-form-item__error {
              padding-left: 80px;
            }
          }
          .el-checkbox {
            margin-bottom: 24px;
            font-size: 14px;
            .el-checkbox__input,
            .el-checkbox__label {
              vertical-align: top;
            }
          }
          .el-icon-view:before {
            content: "";
          }
        }
      }
    }
  }

  .app-footer {
    position: absolute;
    height: 15%;
    width: 100%;
    // padding: 20px;
    bottom: 0;
    overflow: hidden;
    background: #fff;
    p {
      padding: 0;
      margin: 0;
      color: #333333;
      font-size: 14px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      line-height: 35px;
      text-align: center;
    }
    .mt60 {
      margin-top: 36px;
    }
  }
  .tip{
    color: #999999;
    font-size: 10px;
    margin-left: 80px;
  }
  .error{
    color: #f56c6c;
  }
  .error_input{
    /deep/ input {
      border-color: #f56c6c!important;
    }
  }
  /deep/.btn {
    width: 150px !important;
    height: 40px;
    margin-right: 12px;
  }
</style>
