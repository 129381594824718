// 前端用crypto-js进行加密， * npm i jsencrypt, 
// 然后页面头引入import JSEncrypt from 'jsencrypt'; 

import JSEncrypt from 'jsencrypt'
const encrypt = new JSEncrypt();
const key = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCVT+pLc1nkz9z803SOmF48bMFn0GYF4ng6nxj0ojUeu4KeNKkkw/nfureTtL77j9RpMjquJzzKdOZfHRvQyuAbaLoaSD1uU47npNiAL05bLYZEoZWvFOar9gNbIesea8MX0DeYncA2Tkr3kUo8K6XBrZ+TcV2Q8NEvm1T536LOGwIDAQAB'
encrypt.setPublicKey(key);
export default encrypt

// password = encrypt.encrypt(‘你的密码’);// 加密后的字符

